import type { AxiosRequestConfig } from 'axios'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
  SWRInfiniteResponse,
} from 'swr/infinite'
import axios from './axios'

export type AxiosRequestConfigWithoutURL = Omit<AxiosRequestConfig, 'url'>

export default function useAxios<Data = unknown, Error = unknown>(
  key: string | null,
  swrConfig?: SWRConfiguration,
  axiosConfig?: AxiosRequestConfigWithoutURL,
): SWRResponse<Data, Error> {
  return useSWR<Data, Error>(
    key,
    (url) => axios.request({ url, ...axiosConfig }).then((res) => res.data),
    swrConfig,
  )
}

export function useAxiosInfinite<Data = unknown, Error = unknown>(
  keyLoader: SWRInfiniteKeyLoader<Data>,
  swrConfig?: SWRInfiniteConfiguration,
  axiosConfig?: AxiosRequestConfigWithoutURL,
): SWRInfiniteResponse<Data, Error> {
  return useSWRInfinite<Data, Error>(
    keyLoader,
    (url) => axios.get(url, axiosConfig).then((res) => res.data),
    swrConfig,
  )
}
