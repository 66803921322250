import '@sendbird/uikit-react/dist/index.css'
import '@perry/app/styles/globals.scss'
import '@perry/app/styles/ag-grid.scss'
import '@perry/app/styles/tailwind.css'
import 'styles/fonts/gt-sectra-display-font.css'

import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Auth0Provider } from '@auth0/auth0-react'
import { AmityProvider } from 'amity/api/browser'
import { AxiosAuthInterceptorInitializer } from 'auth'
import { LDContext, LaunchDarklyProvider } from 'launchdarkly'
import NProgress from 'nprogress'
import { SendbirdProvider } from 'ui/components/sendbird'
import { useAnalytics } from 'ui/lib/analytics'
import { useErrorCallback } from 'ui/lib/logger'
import { GlobalErrorFallback } from '@perry/app/components/content'
import { AuthProvider } from '@perry/app/lib/auth'
import { AxiosProgressInterceptorInitializer } from '@perry/app/lib/axios'
import * as gtag from '@perry/app/lib/gtag'
import { PlatformComponentsProvider, PlatformDialogProvider } from '../components/platform'
import { GeolocationProvider } from '../lib/geolocation'
import { RoutingProvider } from '../lib/navigation'

const LD_CONTEXT: LDContext = {
  appType: {
    key: 'web',
    name: 'Web application',
  },
}

export default function PerryApp({ Component, pageProps }) {
  useGoogleAnalytics()
  useAnalytics()
  useNprogress()
  const router = useRouter()

  const onErrorCallback = useErrorCallback('PerryApp')

  const onRedirectCallback = async (appState: Record<string, any>) => {
    await router.replace(appState?.returnTo || window.location.pathname)
  }

  const [origin, setOrigin] = useState('')
  useEffect(() => {
    setOrigin(window.location.origin)
  }, [])

  useEffect(() => {
    // This is the simplest but hacky way to require HTTPS for
    // NextJS application inside the Google App Engine Flex.
    // It's required since we do not have ability to make server side
    // redirect without significant changes to the application or
    // to the deployment infrastructure
    if (process.env.NODE_ENV === 'production' && location.protocol !== 'https:') {
      location.replace(`https:${location.href.substring(location.protocol.length)}`)
    }
  }, [])

  return (
    <>
      <Head>
        <title>meetperry</title>
        <meta property="og:title" content="meetperry" key="ogTitle" />
        <meta
          name="description"
          content="A selective community for sophisticated investors. Together we curate, collaborate and create access to alternative investments. At meetperry, we are changing the way we share and invest."
          key="description"
        />
        <meta
          property="og:description"
          content="A selective community for sophisticated investors. Together we curate, collaborate and create access to alternative investments. At meetperry, we are changing the way we share and invest."
          key="ogDescription"
        />
      </Head>

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              // https://developers.google.com/analytics/devguides/collection/gtagjs/pages#manual_pageviews
              send_page_view: false
            });
          `,
        }}
      />
      <ErrorBoundary onError={onErrorCallback} FallbackComponent={GlobalErrorFallback}>
        <Auth0Provider
          domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
          audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
          clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
          redirectUri={origin}
          cacheLocation="localstorage"
          useRefreshTokens={true}
          onRedirectCallback={onRedirectCallback}
        >
          <AuthProvider>
            <AmityProvider>
              <RoutingProvider>
                <AxiosProgressInterceptorInitializer>
                  <AxiosAuthInterceptorInitializer>
                    <LaunchDarklyProvider specificContext={LD_CONTEXT}>
                      <SendbirdProvider>
                        <GeolocationProvider>
                          <PlatformComponentsProvider>
                            <PlatformDialogProvider>
                              <Component {...pageProps} />
                            </PlatformDialogProvider>
                          </PlatformComponentsProvider>
                        </GeolocationProvider>
                      </SendbirdProvider>
                    </LaunchDarklyProvider>
                  </AxiosAuthInterceptorInitializer>
                </AxiosProgressInterceptorInitializer>
              </RoutingProvider>
            </AmityProvider>
          </AuthProvider>
        </Auth0Provider>
      </ErrorBoundary>
    </>
  )
}

const useGoogleAnalytics = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}

const useNprogress = () => {
  const router = useRouter()
  const start = () => setTimeout(() => NProgress.start(), 500)
  const done = () => setTimeout(() => NProgress.done(), 500)

  useEffect(() => {
    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', done)
    router.events.on('routeChangeError', done)

    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', done)
      router.events.off('routeChangeError', done)
    }
  }, [])
}
