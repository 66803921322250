import { useEffect } from 'react'
import { LDFlag, useFlag } from 'launchdarkly'
import { useRouter } from './useRouter'

export const useFlagRedirect = (flag: LDFlag, redirectUrl: string) => {
  const shouldRedirect = useFlag<boolean>(flag)
  const { replace } = useRouter()

  useEffect(() => {
    if (shouldRedirect) {
      replace(redirectUrl)
    }
  }, [shouldRedirect])
}
