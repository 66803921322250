import { ComponentProps, Fragment } from 'react'
import { UserSocialProfilePreview } from 'api/dto'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'
import { useRoutingContext } from 'ui/lib/navigation'

interface NominatedByProps extends ComponentProps<'div'> {
  readonly members: UserSocialProfilePreview[]
}

export function NominatedBy({ members, className, ...props }: NominatedByProps) {
  const { baseUrl } = useRoutingContext()

  return members.length ? (
    <div className={cn('flex gap-2', className)} {...props}>
      <span className="text-deep-teal-500 whitespace-nowrap text-xs font-medium leading-5">
        Nominated by
      </span>
      <p className="whitespace-break-spaces text-sm font-semibold leading-5">
        {members.map((member, index, { length }) => (
          <Fragment key={member.id}>
            <Link
              href={`${baseUrl}/members/${member.userId}`}
              className="inline text-orange-600 hover:text-orange-800"
            >
              {`${member.firstName} ${member.lastName}`}
            </Link>
            {index < length - 1 ? <span className="text-orange-600">, </span> : null}
          </Fragment>
        ))}
      </p>
    </div>
  ) : null
}
