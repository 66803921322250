import { ComponentProps, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InvestmentOpportunity } from 'api/cms'
import { InvestmentOpportunityIoi } from 'api/dto'
import { axios } from 'api/lib'
import { cn } from 'ui/lib'
import { analytics } from 'ui/lib/analytics'
import { Button, Field } from 'ui/src/components/forms'
import { CurrencyInput } from 'ui/src/components/forms/CurrencyInput'
import { useYup } from 'ui/src/lib'
import { formatMoney } from 'ui/src/lib/formatters'
import { useAmountValidator } from './useAmountValidator'
import { Alert } from '../Alert'
import { Dialog } from '../Dialog'

type IoiRequest = Partial<
  Pick<InvestmentOpportunityIoi, 'id' | 'amount' | 'investmentOpportunityId'>
>

interface CoreIoiDialogProps extends Omit<ComponentProps<typeof Dialog>, 'actions' | 'onSubmit'> {
  readonly investment: InvestmentOpportunity
  readonly currentIoi?: InvestmentOpportunityIoi
  readonly onSubmit: (ioi: IoiRequest) => void
}

async function submitIoiRequest(ioiRequest: IoiRequest) {
  const { data: ioi } = await axios.post<InvestmentOpportunityIoi>('/api/ioi', ioiRequest)
  return ioi
}

export function CoreIoiDialog({
  investment,
  currentIoi,
  onSubmit,
  onClose,
  ...props
}: CoreIoiDialogProps) {
  const [isLoading, setIsLoading] = useState(false)
  const amountValidator = useAmountValidator(
    investment.minInvestmentAmount,
    investment.maxInvestmentAmount,
  )

  const resolver = useYup(amountValidator)

  const form = useForm<IoiRequest>({
    resolver,
    defaultValues: { amount: currentIoi?.amount, investmentOpportunityId: investment.id },
  })

  const onSubmitHandler = useCallback(
    async (data: IoiRequest) => {
      setIsLoading(true)
      try {
        const ioi = await submitIoiRequest({
          ...data,
          investmentOpportunityId: investment.id,
          id: currentIoi?.id,
        })
        analytics?.track('Submitted IOI', {
          action: 'Submitted IOI',
          investmentId: investment.id,
          fundName: investment.title,
          stage: 'core',
        })
        onSubmit(ioi)
      } catch (e) {
        console.error(e)
      }

      setIsLoading(false)
    },
    [investment.id, investment.title, currentIoi?.id],
  )

  const actions = (
    <>
      <Button
        className="w-full font-medium"
        disabled={isLoading}
        onClick={form.handleSubmit(onSubmitHandler)}
      >
        SUBMIT
      </Button>
      <Button
        uiType="regular"
        disabled={isLoading}
        className="w-full font-medium"
        onClick={onClose}
      >
        CLOSE
      </Button>
    </>
  )

  return (
    <Dialog actions={actions} onClose={onClose} {...props}>
      <div className="mb-2 mt-3 sm:mt-5">
        <Dialog.Title
          as="h3"
          className={cn(
            'text-lg font-semibold leading-6 text-gray-900',
            'pr-14 sm:pr-0', // To not overlay the close button on mobile
          )}
        >
          How much would you be interested in investing in {investment.title}?
        </Dialog.Title>
      </div>
      <div className="flex w-full flex-col justify-center gap-4">
        <FormProvider {...form}>
          <form className="w-full" data-cy="ioi-form" data-testid="ioi-form">
            <Field
              label="Investment Amount"
              name="amount"
              className="w-full"
              error={form.formState.errors.amount}
            >
              <CurrencyInput placeholder="0.00" data-testid="amount" control={form.control} />
            </Field>
          </form>
        </FormProvider>
        <Alert className="w-full" type="info">
          You can invest between {formatMoney(investment.minInvestmentAmount)} and{' '}
          {formatMoney(investment.maxInvestmentAmount)}.
        </Alert>
      </div>
    </Dialog>
  )
}
