import { ComponentProps, FC, Fragment, ReactElement } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { cn } from 'ui/lib'

export interface DialogProps extends Omit<ComponentProps<'div'>, 'title'> {
  readonly initialFocus?: ComponentProps<typeof HeadlessDialog>['initialFocus']
  readonly showCloseButton?: boolean
  readonly showBackdrop?: boolean
  readonly onClose?: () => void
  readonly open: boolean
  readonly width?: DialogWidth
  readonly title?: string | ReactElement
  readonly actions?: ReactElement
  readonly containerClassName?: string
  readonly fullScreen?: boolean
  readonly fullScreenMobile?: boolean
}

export type DialogWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const DIALOG_WIDTH: Readonly<Record<DialogWidth, string>> = {
  xs: 'max-w-xs',
  sm: 'max-w-md',
  md: 'max-w-xl',
  lg: 'max-w-3xl',
  xl: 'max-w-6xl',
}

export const Dialog: FC<DialogProps> & {
  Backdrop: typeof HeadlessDialog.Backdrop
  Panel: typeof HeadlessDialog.Panel
  Overlay: typeof HeadlessDialog.Overlay
  Title: typeof HeadlessDialog.Title
  Description: typeof HeadlessDialog.Description
} = ({
  actions,
  children,
  open,
  onClose = () => {
    /* do nothing */
  },
  showBackdrop = true,
  showCloseButton = true,
  title,
  width = 'md',
  containerClassName,
  initialFocus,
  fullScreen = false,
  fullScreenMobile = false,
}) => {
  const sizeCss = DIALOG_WIDTH[width]

  return (
    <Transition.Root show={open} as={Fragment}>
      <HeadlessDialog
        as="div"
        initialFocus={initialFocus}
        data-testid="dialog"
        className={cn(
          'fixed inset-0 z-10',
          fullScreenMobile && !fullScreen && 'z-40 sm:z-10',
          fullScreen && 'z-40',
        )}
        onClose={onClose}
      >
        <div
          className={cn(
            'flex h-full min-h-full items-center justify-center',
            'overflow-hidden text-center',
            'p-2 sm:p-8 sm:py-16',
            (fullScreenMobile || fullScreen) && 'p-0',
          )}
        >
          {showBackdrop && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessDialog.Overlay
                data-testid="dialog-backdrop"
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              />
            </Transition.Child>
          )}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* Below is the first visible element */}
            <div
              className={cn(
                'flex transform flex-col overflow-hidden rounded-lg',
                'bg-white text-left align-bottom shadow-xl transition-all sm:align-middle',
                'px-4 pb-4 pt-5 sm:p-6',
                // Sizing
                'max-h-full',
                fullScreenMobile &&
                  !fullScreen &&
                  `h-full w-screen rounded-none px-0 pb-0 pt-12 sm:h-auto sm:rounded-lg sm:${sizeCss}`,
                fullScreen ? 'h-full rounded-none p-0' : sizeCss,
              )}
            >
              {showCloseButton && (
                <div
                  className={cn(
                    'absolute right-0 top-0 pr-5 pt-4',
                    fullScreenMobile && 'top-8 sm:top-0',
                  )}
                >
                  <button
                    data-testid="dialog-close-button"
                    type="button"
                    className="text-deep-teal-400 rounded-md bg-white hover:text-orange-600 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              {title && (
                <HeadlessDialog.Title
                  as="h3"
                  className={cn(
                    'text-deep-teal-500 h-6 shrink-0 truncate pr-6 text-lg font-medium leading-6',
                    showCloseButton && 'pr-14',
                  )}
                >
                  {title}
                </HeadlessDialog.Title>
              )}
              <div className={cn('mt-2 flex-1 overflow-y-auto', containerClassName)}>
                {children}
              </div>
              {actions && (
                <div
                  className={cn(
                    'mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse',
                    (fullScreen || fullScreenMobile) && 'mt-0',
                  )}
                >
                  {actions}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  )
}

// Re-export HeadlessDialog components
Dialog.Backdrop = HeadlessDialog.Backdrop
Dialog.Panel = HeadlessDialog.Panel
Dialog.Overlay = HeadlessDialog.Overlay
Dialog.Title = HeadlessDialog.Title
Dialog.Description = HeadlessDialog.Description
