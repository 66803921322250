import { ComponentPropsWithoutRef, FC } from 'react'

const MeetperryIcon: FC<ComponentPropsWithoutRef<'svg'>> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 17 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.71114 1.17017C7.34527 1.17017 7.83944 1.34838 8.19366 1.70481C8.37411 1.89508 8.51407 2.11892 8.60531 2.36316C8.69656 2.60739 8.73725 2.86711 8.72499 3.12704V7.27055H7.89899V3.35381C7.89899 2.3526 7.45265 1.85199 6.55998 1.85199C6.35213 1.84449 6.14516 1.88219 5.95382 1.96241C5.76248 2.04262 5.5915 2.16337 5.45305 2.31605C5.1655 2.64552 5.01596 3.07016 5.03471 3.50399V7.27055H4.20871V3.35381C4.20871 2.3526 3.76288 1.85199 2.87123 1.85199C2.68076 1.84479 2.49073 1.87494 2.31222 1.94069C2.13371 2.00644 1.97029 2.10647 1.83147 2.23495C1.55196 2.50432 1.38388 2.86602 1.35969 3.25018V7.27055H0.533691V1.33086H1.30931V2.05624C1.68388 1.46452 2.26763 1.16916 3.06055 1.17017C3.90945 1.17017 4.49218 1.50257 4.80874 2.16737C5.23421 1.50257 5.86834 1.17017 6.71114 1.17017Z"
      fill="white"
    />
    <path
      d="M13.2504 0.800684C13.6378 0.791707 14.0221 0.86968 14.3742 1.02868C14.7264 1.18768 15.037 1.42352 15.2826 1.7183C15.8109 2.32803 16.0755 3.129 16.0765 4.12121C16.0776 5.11341 15.8104 5.91438 15.275 6.52412C15.0281 6.81846 14.7165 7.05384 14.3637 7.21253C14.0109 7.37121 13.6261 7.44907 13.2382 7.44023C12.3954 7.44023 11.7577 7.16539 11.3251 6.61573V9.4857H9.69451V0.991415H11.2076V1.81591C11.6687 1.13909 12.3496 0.800684 13.2504 0.800684ZM11.7053 5.52991C11.8437 5.69937 12.02 5.83511 12.2205 5.92654C12.421 6.01797 12.6402 6.06264 12.8611 6.05705C13.0795 6.06441 13.2967 6.02075 13.4946 5.92965C13.6926 5.83854 13.8658 5.70258 14.0001 5.53292C14.2851 5.18149 14.4276 4.71142 14.4276 4.12271C14.4276 3.53399 14.2871 3.06242 14.0062 2.70799C13.8748 2.53679 13.7034 2.39933 13.5064 2.30729C13.3094 2.21525 13.0928 2.17136 12.8748 2.17935C12.652 2.17349 12.4309 2.21875 12.2289 2.31155C12.027 2.40435 11.8498 2.54214 11.7114 2.714C11.3958 3.11595 11.2361 3.61539 11.261 4.12271C11.2373 4.62844 11.3945 5.12635 11.7053 5.52991Z"
      fill="white"
    />
  </svg>
)

export default MeetperryIcon
