import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

const TYPE_COLOR_MAP: Record<string, string> = {
  'hedge-funds': 'text-legend-terracotta',
  'private-credit': 'text-legend-amethyst',
  'private-equity': 'text-legend-sienna',
  'real-estate': 'text-legend-cerulean',
  'venture-capital': 'text-legend-teal',
}

export const AltInvestmentTypeTag: FC<
  ComponentProps<'span'> & { slug?: string; typeName?: string }
> = ({ slug, typeName, className, ...props }) => {
  const textColor = slug ? TYPE_COLOR_MAP[slug] : 'text-legend-matterhorn'

  return (
    <>
      {typeName && (
        <span
          className={clsx(
            'bg-legend-platinum self-start rounded px-2.5 py-0.5 text-xs font-semibold uppercase',
            textColor,
            className,
          )}
          {...props}
        >
          {typeName}
        </span>
      )}
    </>
  )
}
