import type {
  CatalogType as CatalogTypeDB,
  InviteAccreditation as InviteAccreditationDB,
  InviteReference as InviteReferenceDB,
  InviteStatus as InviteStatusDB,
  IoiStatus as IoiStatusDB,
  NominationStatus as NominationStatusDB,
  PermissionKey as PermissionKeyDB,
  Role as RoleDB,
  SelfAccreditation as SelfAccreditationDB,
  SubscriptionStatus as SubscriptionStatusDB,
  UserSocialProfileActivationStatus as UserSocialProfileActivationStatusDB,
  UserSocialProfileRestrictions as UserSocialProfileRestrictionsDB,
  UserStatus as UserStatusDB,
  ProspectStatus as ProspectStatusDB,
  NominationSource as NominationSourceDB,
  UserPhoneNumberConsent as UserPhoneNumberConsentDB,
  NominationEmailType as NominationEmailTypeDB,
  AddressType as AddressTypeDB,
  GiftType as GiftTypeDB,
  OnboardingStepName as OnboardingStepNameDB,
  OnboardingStepStatus as OnboardingStepStatusDB,
  InvestmentOpportunityIoiSubmissionStage as InvestmentOpportunityIoiSubmissionStageDB,
  IoiVisibility as IoiVisibilityDB,
} from 'database'

export const CatalogType = {
  PLANNING_GOALS: 'PLANNING_GOALS',
  GROSS_ANNUAL_HOUSEHOLD_INCOME: 'GROSS_ANNUAL_HOUSEHOLD_INCOME',
} as const satisfies typeof CatalogTypeDB
export type CatalogTypeEnum = keyof typeof CatalogType

export const InviteAccreditation = {
  MORE_THEN_5000K: 'MORE_THEN_5000K',
  MORE_THEN_2500K_LESS_THEN_5000K: 'MORE_THEN_2500K_LESS_THEN_5000K',
  LESS_THEN_2500K: 'LESS_THEN_2500K',
} as const satisfies typeof InviteAccreditationDB
export type InviteAccreditationEnum = keyof typeof InviteAccreditation

export const InviteReference = {
  MEMBER: 'MEMBER',
  WORD_OF_MOUTH: 'WORD_OF_MOUTH',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  OTHER: 'OTHER',
} as const satisfies typeof InviteReferenceDB
export type InviteReferenceEnum = keyof typeof InviteReference

export const InviteStatus = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  USED: 'USED',
  ARCHIVED: 'ARCHIVED',
} as const satisfies typeof InviteStatusDB
export type InviteStatusEnum = keyof typeof InviteStatus

export const IoiStatus = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
} as const satisfies typeof IoiStatusDB
export type IoiStatusEnum = keyof typeof IoiStatus

export const NominationStatus = {
  SENT: 'SENT',
  ACCEPTED: 'ACCEPTED',
  PROSPECT_CREATED: 'PROSPECT_CREATED',
  DUPLICATE: 'DUPLICATE',
} as const satisfies typeof NominationStatusDB
export type NominationStatusEnum = keyof typeof NominationStatus

export const PermissionKey = {
  NOMINATIONS_ALLOWED: 'NOMINATIONS_ALLOWED',
  NOMINATIONS_LIMIT: 'NOMINATIONS_LIMIT',
} as const satisfies typeof PermissionKeyDB
export type PermissionKeyEnum = keyof typeof PermissionKey

export const Role = {
  ADMIN: 'ADMIN',
  SIGNUP: 'SIGNUP',
  INVESTOR: 'INVESTOR',
} as const satisfies typeof RoleDB
export type RoleEnum = keyof typeof Role

export const SelfAccreditation = {
  AI: 'AI',
  QP: 'QP',
  EI: 'EI',
} as const satisfies typeof SelfAccreditationDB
export type SelfAccreditationEnum = keyof typeof SelfAccreditation

export const SubscriptionStatus = {
  INCOMPLETE: 'INCOMPLETE',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
} as const satisfies typeof SubscriptionStatusDB
export type SubscriptionStatusEnum = keyof typeof SubscriptionStatus

export const UserSocialProfileActivationStatus = {
  PENDING: 'PENDING',
  ACTIVATED: 'ACTIVATED',
} as const satisfies typeof UserSocialProfileActivationStatusDB
export type UserSocialProfileActivationStatusEnum = keyof typeof UserSocialProfileActivationStatus

export const UserSocialProfileRestrictions = {
  NONE: 'NONE',
  RESTRICTED: 'RESTRICTED',
} as const satisfies typeof UserSocialProfileRestrictionsDB
export type UserSocialProfileRestrictionsEnum = keyof typeof UserSocialProfileRestrictions

export const UserStatus = {
  CREATED: 'CREATED',
  PROFILE_COMPLETED: 'PROFILE_COMPLETED',
  KYC_REQUESTED: 'KYC_REQUESTED',
  KYC_FAILED: 'KYC_FAILED',
  KYC_APPROVED: 'KYC_APPROVED',
  SUBSCRIBED: 'SUBSCRIBED',
} as const satisfies typeof UserStatusDB
export type UserStatusEnum = keyof typeof UserStatus

export const ProspectStatus = {
  CREATED: 'CREATED',
  LINK_SENT: 'LINK_SENT',
  REGISTERED: 'REGISTERED',
  JOINED: 'JOINED',
  DECLINED_BY_PROSPECT: 'DECLINED_BY_PROSPECT',
  DECLINED_BY_ADMIN: 'DECLINED_BY_ADMIN',
} as const satisfies typeof ProspectStatusDB
export type ProspectStatus = keyof typeof ProspectStatusDB

export const NominationSource = {
  DIRECT_COMMUNICATION: 'DIRECT_COMMUNICATION',
  APP: 'APP',
  CAMPAIGN: 'CAMPAIGN',
  PUBLIC_SITE: 'PUBLIC_SITE',
  EVENT_EMAIL: 'EVENT_EMAIL',
  EVENT_DIRECT_RSVP: 'EVENT_DIRECT_RSVP',
  EVENT_APP_RSVP: 'EVENT_APP_RSVP',
  OTHER: 'OTHER',
} as const satisfies typeof NominationSourceDB
export type NominationSource = keyof typeof NominationSourceDB

export const UserPhoneNumberConsent = {
  UNKNOWN: 'UNKNOWN',
  CONSENTED: 'CONSENTED',
  DECLINED: 'DECLINED',
} as const satisfies typeof UserPhoneNumberConsentDB
export type UserPhoneNumberConsent = keyof typeof UserPhoneNumberConsentDB

export const NominationEmailType = {
  NOMINATION: 'NOMINATION',
  REMINDER: 'REMINDER',
  EVENT_GUEST_RSVP: 'EVENT_GUEST_RSVP',
} as const satisfies typeof NominationEmailTypeDB
export type NominationEmailType = keyof typeof NominationEmailTypeDB

export const AddressType = {
  SHIPPING: 'SHIPPING',
} as const satisfies typeof AddressTypeDB
export type AddressType = keyof typeof AddressTypeDB

export const GiftType = {
  TSHORT: 'TSHORT',
} as const satisfies typeof GiftTypeDB
export type GiftType = keyof typeof GiftTypeDB

export const OnboardingStepName = {
  GIFT_SHIPPING: 'GIFT_SHIPPING',
  PRIVATE_PROFILE: 'PRIVATE_PROFILE',
} as const satisfies typeof OnboardingStepNameDB
export type OnboardingStepName = keyof typeof OnboardingStepNameDB

export const OnboardingStepStatus = {
  PENDING: 'PENDING',
  PASSED: 'PASSED',
  SKIPPED: 'SKIPPED',
} as const satisfies typeof OnboardingStepStatusDB
export type OnboardingStepStatus = keyof typeof OnboardingStepStatusDB

export const InvestmentOpportunityIoiSubmissionStage = {
  CORE: 'CORE',
  OFF_PISTE: 'OFF_PISTE',
  PIPELINE: 'PIPELINE',
} as const satisfies typeof InvestmentOpportunityIoiSubmissionStageDB
export type InvestmentOpportunityIoiSubmissionStage =
  keyof typeof InvestmentOpportunityIoiSubmissionStageDB

export const IoiVisibility = {
  AMOUNT_ONLY: 'AMOUNT_ONLY',
  NAME_AND_AMOUNT: 'NAME_AND_AMOUNT',
  NAME_ONLY: 'NAME_ONLY',
} as const satisfies typeof IoiVisibilityDB
export type IoiVisibility = keyof typeof IoiVisibilityDB
