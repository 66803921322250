import { FC, forwardRef, SelectHTMLAttributes } from 'react'
import { cn } from 'ui/lib'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  readonly placeholder?: string
}

// eslint-disable-next-line react/display-name
export const Select = forwardRef<any, SelectProps>(
  (
    { className, defaultValue, placeholder, children, onChange, disabled, value, ...props },
    ref,
  ) => {
    return (
      <select
        ref={ref}
        role="combobox"
        className={cn(!disabled ? 'cursor-pointer' : 'cursor-not-allowed', className)}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
        {...props}
      >
        {placeholder && !value && (
          <option role="option" hidden className="text-deep-teal-300" value="" disabled>
            {placeholder}
          </option>
        )}
        {children}
      </select>
    )
  },
)

export const SelectItem: FC<{
  disabled?: boolean
  value: any
  name: string
}> = ({ value, name, disabled = false }) => {
  return (
    <option
      role="option"
      className={cn(disabled ? 'text-deep-teal-300' : 'text-deep-teal-600')}
      disabled={disabled}
      value={value}
    >
      {name}
    </option>
  )
}
