import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { InvestmentOpportunity } from 'api/cms'
import { InvestmentOpportunityIoi, IoiSettings, IoiVisibility } from 'api/dto'
import { axios } from 'api/lib'
import { cn } from 'ui/lib'
import { analytics } from 'ui/lib/analytics'
import { Button, Checkbox } from 'ui/src/components/forms'
import { RadioGroup, RadioOption } from 'ui/src/components/forms/RadioGroup'
import { Alert } from '../Alert'
import { Dialog } from '../Dialog'

type IoiVisibilitySettings = Omit<IoiSettings, 'ioiId'>

const DEFAULT_IOI_VISIBILITY_FORM_VALUE = IoiVisibility.NAME_AND_AMOUNT
const DEFAULT_USE_AS_DEFAULT_IOI_VISIBILITY_FORM_VALUE = true

interface IoiVisibilitySettingsDialogProps
  extends Omit<ComponentProps<typeof Dialog>, 'actions' | 'onSubmit'> {
  readonly investment: InvestmentOpportunity
  readonly currentIoi: InvestmentOpportunityIoi
  readonly onSubmit: (settings: IoiVisibilitySettings) => void
}

export function IoiVisibilitySettingsDialog({
  investment,
  currentIoi,
  onSubmit,
  onClose,
  ...props
}: IoiVisibilitySettingsDialogProps) {
  const [isLoading, setIsLoading] = useState(false)
  const form = useForm<IoiVisibilitySettings>()

  const onSubmitHandler = useCallback(
    async (settings: IoiVisibilitySettings) => {
      setIsLoading(true)
      try {
        await axios.put(`/api/ioi/${currentIoi.id}/settings`, {
          ...settings,
          ioiId: currentIoi.id,
        })
        analytics?.track('Set IOI settings', {
          action: 'Set IOI settings',
          investmentId: investment.id,
          fundName: investment.title,
          ...settings,
        })
        onSubmit(settings)
      } catch (e) {
        console.error(e)
      }

      setIsLoading(false)
    },
    [investment.id, investment.title, currentIoi.id],
  )

  useEffect(() => {
    if (!currentIoi.ioiVisibility) {
      form.setValue('ioiVisibility', DEFAULT_IOI_VISIBILITY_FORM_VALUE)
    } else {
      form.setValue('ioiVisibility', currentIoi.ioiVisibility)
    }
    form.setValue('useDefaultIoiVisibility', DEFAULT_USE_AS_DEFAULT_IOI_VISIBILITY_FORM_VALUE)
  }, [currentIoi.ioiVisibility])

  const actions = (
    <>
      <Button
        disabled={isLoading}
        className="w-full font-medium"
        onClick={form.handleSubmit(onSubmitHandler)}
      >
        SAVE
      </Button>
      <Button
        disabled={isLoading}
        uiType="regular"
        className="w-full font-medium"
        onClick={onClose}
      >
        CLOSE
      </Button>
    </>
  )

  return (
    <Dialog actions={actions} onClose={onClose} {...props}>
      <div className="mb-2 mt-3 sm:mt-5">
        <Dialog.Title
          as="h3"
          className={cn(
            'text-lg font-semibold leading-6 text-gray-900',
            'pr-14 sm:pr-0', // To not overlay the close button on mobile
          )}
        >
          Your IOI visibility settings
        </Dialog.Title>
      </div>
      <div className="flex w-full flex-col justify-center gap-4">
        <FormProvider {...form}>
          <form
            className="w-full"
            data-cy="ioi-form"
            data-tracksubmit={JSON.stringify({
              action: 'IOI Visibility Settings submitted',
              investmentId: investment.id,
              fundName: investment.title,
              stage: 'core',
            })}
          >
            <Controller
              name="ioiVisibility"
              control={form.control}
              render={({ field: { value, onChange, ...props } }) => (
                <RadioGroup
                  value={value && value.toString()}
                  label="ioiVisibility"
                  onChange={onChange}
                  {...props}
                >
                  <RadioOption
                    value={IoiVisibility.NAME_AND_AMOUNT}
                    label="Show my name and IOI amount in the list of IOId members"
                  />

                  <RadioOption
                    value={IoiVisibility.NAME_ONLY}
                    label="Show my name but hide my IOI amount"
                    description={`Note: if you are the first member to submit an IOI without specifying the 
                      amount, your IOI will appear as “Anonymous” until another member submits a 
                      private IOI. `}
                  />
                  <RadioOption
                    value={IoiVisibility.AMOUNT_ONLY}
                    label="Do not show my name (your IOI amount will be shared anonymously)"
                  />
                </RadioGroup>
              )}
            />
            <Checkbox
              className="px-4 pt-8"
              inputClasses="text-deep-teal-500 focus:ring-deep-teal-500"
              label="Make the above selection my default for all future IOIs"
              {...form.register('useDefaultIoiVisibility')}
            />

            <Alert className="mb-4 w-full">
              You can change your visibility settings at any time for each deal.
            </Alert>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  )
}
