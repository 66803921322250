import { ComponentProps, MouseEventHandler, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { InvestmentOpportunityExtended } from 'api/dto'
import { TotalIoisPreview } from 'ui/components/investments'
import { cn } from 'ui/lib'
import { Button } from 'ui/src/components/forms'
import {
  CoreIoiDialog,
  IoisListDialog,
  IoiVisibilitySettingsDialog,
  OffPistePipelineIoiDialog,
  IoiWithdrawConfirmationDialog,
} from '../IoiDialogs'

type Steps = 'list' | 'amount' | 'visibility' | 'withdraw'

interface TotalIoisButtonProps extends ComponentProps<typeof Button> {
  readonly investment: InvestmentOpportunityExtended
  readonly onMutate: () => void
}

export function TotalIoisButton({
  className,
  investment,
  onMutate,
  ...rest
}: TotalIoisButtonProps) {
  const [step, setStep] = useState<Steps>('list')
  const [open, setOpen] = useState(false)

  const { stage, membersHaveSubmittedIOI = [], totalIOIs = 0 } = investment

  const hasTotalIois = totalIOIs > 0 && membersHaveSubmittedIOI.length > 0

  const currentMemberHaveSubmittedIoi = membersHaveSubmittedIOI.find(
    (member) => member.isCurrentUserIOI,
  )

  const currentIoi = currentMemberHaveSubmittedIoi?.ioi!

  const onOpenHandler: MouseEventHandler = useCallback(
    (event) => {
      setOpen(true)
      event.preventDefault() // HACK to catch all clicks and prevent default to not open investment on card click
    },
    [setOpen],
  )

  const onEditAmountHandler = useCallback(() => {
    setStep('amount')
  }, [setStep])

  const onEditVisibilityHandler = useCallback(() => {
    setStep('visibility')
  }, [setStep])

  const onWithdrawHandler = useCallback(() => {
    setStep('withdraw')
  }, [setStep])

  const onGoBackHandler = useCallback(() => {
    setStep('list')
  }, [setStep])

  const submitIoiAmountHandler = useCallback(() => {
    onMutate()
    setStep('list')
  }, [onMutate, setStep])

  const submitIoiVisibilitySettingsHandler = useCallback(() => {
    onMutate()
    setStep('list')
  }, [onMutate, setStep])

  const withdrawHandler = useCallback(() => {
    onMutate()
    setStep('list')
  }, [onMutate, setStep])

  const cancelHandler = useCallback(() => {
    setStep('list')
  }, [setStep])

  const onCloseHandler = useCallback(() => {
    setStep('list')
    setOpen(false)
  }, [setOpen])

  let dialog: JSX.Element | null = null

  if (open) {
    switch (step) {
      case 'list':
        dialog = (
          <IoisListDialog
            investment={investment}
            onEditAmount={onEditAmountHandler}
            onEditVisibility={onEditVisibilityHandler}
            onWithdraw={onWithdrawHandler}
            onClose={onCloseHandler}
          />
        )
        break
      case 'amount':
        switch (stage) {
          case 'core':
            if (currentIoi) {
              dialog = (
                <CoreIoiDialog
                  investment={investment}
                  currentIoi={currentIoi}
                  onSubmit={submitIoiAmountHandler}
                  onClose={onGoBackHandler}
                />
              )
            }
            break
          default:
            if (currentIoi) {
              dialog = (
                <OffPistePipelineIoiDialog
                  investment={investment}
                  currentIoi={currentIoi}
                  onSubmit={submitIoiAmountHandler}
                  onClose={onGoBackHandler}
                />
              )
            }
            break
        }
        break
      case 'visibility':
        if (currentIoi) {
          dialog = (
            <IoiVisibilitySettingsDialog
              investment={investment}
              currentIoi={currentIoi}
              onSubmit={submitIoiVisibilitySettingsHandler}
              onClose={onGoBackHandler}
            />
          )
        }
        break
      case 'withdraw':
        if (currentIoi) {
          dialog = (
            <IoiWithdrawConfirmationDialog
              investment={investment}
              onWithdraw={withdrawHandler}
              onClose={cancelHandler}
            />
          )
        }
        break
    }
  }

  return hasTotalIois ? (
    <>
      {dialog &&
        createPortal(
          <div
            className="empty:hidden"
            onClick={(event) => {
              event.stopPropagation() // HACK to catch all clicks and prevent default to not open investment on card click
            }}
          >
            {dialog}
          </div>,
          document.body,
        )}
      <Button
        className={cn('flex justify-center', className)}
        uiType="regular"
        onClick={onOpenHandler}
        {...rest}
      >
        <TotalIoisPreview
          uiSize="sm"
          className="hidden sm:flex"
          totalIois={totalIOIs}
          membersHaveSubmittedIoi={membersHaveSubmittedIOI}
        />
      </Button>
    </>
  ) : null
}
