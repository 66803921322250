import { HTMLAttributes } from 'react'
import { cn } from 'ui/lib'

export type AlertType = 'info' | 'default'

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  readonly type?: AlertType
  readonly title?: string
}

type AlertThemeColors = {
  readonly bg: string
  readonly title: string
  readonly text: string
}

const ALERT_TYPES: Readonly<Record<AlertType, AlertThemeColors>> = {
  default: {
    bg: 'bg-twilight-gray-100',
    title: 'text-twilight-gray-900',
    text: 'text-twilight-gray-800',
  },
  info: {
    bg: 'bg-indigo-100',
    title: 'text-twilight-gray-900',
    text: 'text-twilight-gray-800',
  },
}

export function Alert({ className, children, type = 'default', title }: AlertProps) {
  const css = ALERT_TYPES[type]

  return (
    <div className={cn('rounded-md p-4', css.bg, className)} role="alert">
      <div className="flex">
        <div className="flex-1 space-y-2">
          {title && <h3 className={cn('text-sm font-medium', css.title)}>{title}</h3>}
          {children && <div className={cn('text-sm font-normal', css.text)}>{children}</div>}
        </div>
      </div>
    </div>
  )
}
