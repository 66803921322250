import { ComponentPropsWithoutRef, FC } from 'react'

const OffPisteIcon: FC<ComponentPropsWithoutRef<'svg'>> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.84095 0.150645C9.91181 0.10842 10.0299 0.0615035 10.1008 0.038045C10.1717 0.0192783 10.2898 0.000511574 10.3701 0.000511574C10.4457 -0.00418011 10.6157 0.02397 10.748 0.0615035C10.9181 0.113112 11.0409 0.188179 11.1827 0.343004C11.2961 0.460297 11.4094 0.615122 11.4378 0.685497C11.4661 0.760564 11.4898 0.934157 11.4898 1.07491C11.4898 1.21566 11.4567 1.4174 11.4142 1.52062C11.3669 1.62383 11.2772 1.76928 11.2063 1.83965C11.1354 1.91472 10.9937 2.01794 10.8898 2.07424C10.7528 2.14461 10.611 2.17276 10.3937 2.17276C10.2094 2.17276 10.0299 2.14461 9.94488 2.09769C9.86457 2.06016 9.73228 1.95225 9.64252 1.8678C9.55276 1.77866 9.44409 1.62383 9.39685 1.52062C9.34961 1.4174 9.30709 1.22973 9.30709 1.09837C9.30709 0.962307 9.34961 0.774639 9.4063 0.652656C9.45827 0.535363 9.54803 0.389921 9.60472 0.333621C9.66142 0.272629 9.77008 0.188179 9.84095 0.150645Z"
      fill="currentColor"
    />
    <path
      d="M4.1811 4.19488C4.1811 3.82423 4.2 3.73978 4.31339 3.52397C4.38425 3.3926 4.46929 3.28 4.50236 3.28C4.53071 3.28 4.68661 3.36914 4.84252 3.47705C4.99843 3.58496 5.66457 4.05413 6.32598 4.5186C6.98268 4.98777 7.63465 5.47101 7.77165 5.60238C7.91339 5.73375 8.07402 5.94957 8.14488 6.09501C8.21575 6.24983 8.26772 6.4375 8.26772 6.56418C8.26772 6.68147 8.23464 6.86914 8.19213 6.98643C8.15433 7.10372 8.05984 7.282 7.98898 7.38522C7.91339 7.48844 7.5874 7.88723 7.25669 8.27664C6.93071 8.66136 6.66142 8.99916 6.66142 9.02731C6.66142 9.05546 7.37953 9.47771 8.25827 9.97503C9.13228 10.4723 9.93071 10.9087 10.0252 10.9509C10.1244 10.9884 10.337 11.0213 10.4976 11.0213C10.7528 11.026 10.8236 11.0025 10.9843 10.8899C11.0882 10.8148 11.2063 10.721 11.2441 10.6741C11.2819 10.6319 11.3764 10.5849 11.4567 10.5662C11.5417 10.5521 11.6598 10.5709 11.7496 10.6178C11.8346 10.66 11.9244 10.7351 11.9528 10.7867C11.9764 10.8383 12 10.965 12 11.0682C12 11.2277 11.9669 11.2934 11.7732 11.4951C11.6504 11.6265 11.4378 11.786 11.2913 11.8564C11.0976 11.9455 10.937 11.9831 10.6535 11.9971C10.4457 12.0065 10.148 11.9925 9.99213 11.9596C9.82677 11.9268 9.51968 11.8048 9.25984 11.664C9.01417 11.5327 7.19528 10.5099 5.22047 9.38857C3.24567 8.26726 1.35118 7.18348 1.01575 6.98174C0.675591 6.78469 0.311811 6.55479 0.198425 6.47504C0.0141732 6.33898 0 6.31083 0 6.11847C0 5.96833 0.0283465 5.8745 0.103937 5.78536C0.174803 5.71029 0.27874 5.65868 0.401575 5.63992C0.581102 5.61646 0.722835 5.69152 5.3622 8.32825L5.68819 7.92946C5.86299 7.70895 6.10394 7.3993 6.21732 7.24447C6.33071 7.08965 6.42047 6.91605 6.4252 6.85506C6.4252 6.79876 6.39213 6.69085 6.35433 6.61109C6.30709 6.51257 6.05197 6.32021 5.53701 5.98241C5.13071 5.71498 4.71496 5.41002 4.61575 5.30211C4.52126 5.1989 4.38425 4.99715 4.31339 4.86579C4.2 4.64997 4.1811 4.56552 4.1811 4.19488Z"
      fill="currentColor"
    />
    <path
      d="M3.32126 0.934157C3.33071 0.920082 3.39685 0.83094 3.46772 0.732414C3.54331 0.638581 3.62362 0.558822 3.65669 0.558822C3.68504 0.55413 3.75118 0.601047 3.80315 0.657347C3.86929 0.723031 3.90236 0.83094 3.90709 0.99984C3.91181 1.13121 3.94488 1.26727 3.97795 1.3048C4.01102 1.33764 4.30866 1.56753 4.63937 1.80681C4.9748 2.05078 5.25354 2.24783 5.26772 2.24783C5.28189 2.24783 5.39055 2.17276 5.51339 2.07893C5.64094 1.98509 5.83937 1.85842 5.9622 1.79743C6.08976 1.73643 6.34488 1.63322 6.52913 1.57223C6.71811 1.51123 7.06299 1.43617 7.29921 1.40332C7.53071 1.37048 7.87087 1.35172 8.05512 1.36579C8.27717 1.37987 8.46142 1.42678 8.61732 1.50185C8.74488 1.56284 8.90551 1.68483 8.9811 1.76458C9.05669 1.84903 9.14173 2.00855 9.17008 2.11646C9.20315 2.23375 9.2126 2.45426 9.1937 2.64662C9.17953 2.82491 9.08976 3.26123 8.99528 3.60842C8.90079 3.9556 8.81575 4.27464 8.80157 4.31217C8.7874 4.35909 9 4.52799 9.48189 4.85171C9.86457 5.10976 10.2709 5.41002 10.3795 5.51793C10.4929 5.63053 10.5969 5.77597 10.611 5.84635C10.6299 5.94018 10.611 6.00117 10.5496 6.06217C10.5024 6.10908 10.3984 6.14662 10.3087 6.14193C10.2283 6.14193 9.99213 6.08093 9.78898 6.00587C9.59055 5.93549 9.16063 5.75252 8.83465 5.60707C8.50866 5.46163 8.14488 5.26927 8.01732 5.18013C7.89449 5.0863 7.7622 4.94555 7.72441 4.85171C7.67717 4.7438 7.65354 4.53268 7.65354 4.24179C7.65354 3.90868 7.63937 3.80078 7.59213 3.81485C7.56378 3.82423 7.39843 3.88992 6.92126 4.08228L6.16535 3.52397C5.74961 3.21901 5.00315 2.68415 3.61417 1.68952L3.40157 1.78804C3.25984 1.85373 3.14173 1.87718 3.04724 1.85842C2.96693 1.84434 2.87717 1.80212 2.84409 1.76928C2.79685 1.72236 2.82047 1.66606 2.95276 1.48308C3.04252 1.3611 3.10394 1.23442 3.08976 1.20158C3.07087 1.17343 3.0189 1.10306 2.96693 1.05145C2.91968 0.99984 2.89134 0.920082 2.90551 0.873165C2.92913 0.798098 2.93858 0.798098 3.11811 0.873165C3.22205 0.920082 3.31654 0.948232 3.32126 0.934157Z"
      fill="currentColor"
    />
  </svg>
)

export default OffPisteIcon
