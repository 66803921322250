import { ComponentProps, ComponentPropsWithoutRef, MouseEventHandler, useCallback } from 'react'
import {
  InvestmentOpportunityExtended,
  IoiVisibility,
  MemberHaveSubmittedIoi as MemberHaveSubmittedIoiDto,
} from 'api/dto'
import { AmityAvatar } from 'ui/components/content'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'
import { useRoutingContext } from 'ui/lib/navigation'
import { formatMoney } from 'ui/src/lib/formatters'
import { Dialog } from '../Dialog'

interface IoisListDialogProps extends Omit<ComponentProps<typeof Dialog>, 'actions' | 'onSubmit'> {
  readonly investment: InvestmentOpportunityExtended
  readonly onEditAmount: () => void
  readonly onEditVisibility: () => void
  readonly onWithdraw: () => void
  readonly onClose: () => void
}

export function IoisListDialog({
  className,
  investment,
  onEditAmount,
  onEditVisibility,
  onWithdraw,
  onClose,
  ...props
}: IoisListDialogProps) {
  const { membersHaveSubmittedIOI = [], totalIOIs = 0 } = investment

  const ioisCount = membersHaveSubmittedIOI.length

  const currentMemberHaveSubmittedIoi = membersHaveSubmittedIOI.find(
    (member) => member.isCurrentUserIOI,
  )

  const otherMembersHaveSubmittedIoi = membersHaveSubmittedIOI.filter(
    (member) => !member.isCurrentUserIOI,
  )

  return (
    <Dialog actions={undefined} onClose={onClose} {...props}>
      <Dialog.Title as="h3" className="mt-5 text-lg font-semibold leading-6 text-gray-900 sm:-mt-1">
        Interested Members
      </Dialog.Title>
      <div className={cn('flex flex-col gap-4', className)}>
        <div className="text-primary-800 flex flex-wrap gap-x-1">
          <span>Total Indications of Interest (IOIs):</span>
          <span className="font-semibold">
            {`${ioisCount} 
        member${ioisCount > 1 ? 's' : ''} | ${formatMoney(totalIOIs)}`}
          </span>
        </div>
        <div className="divide-twilight-gray-200 flex flex-col divide-y-2">
          {currentMemberHaveSubmittedIoi && (
            <MemberHaveSubmittedIoi
              className="pb-4"
              membersHaveSubmittedIoi={currentMemberHaveSubmittedIoi}
              onEditAmount={onEditAmount}
              onEditVisibility={onEditVisibility}
              onWithdraw={onWithdraw}
            />
          )}
          {otherMembersHaveSubmittedIoi.map((memberHaveSubmittedIoi) => (
            <MemberHaveSubmittedIoi
              key={memberHaveSubmittedIoi.id}
              className="py-4 last:pb-0"
              membersHaveSubmittedIoi={memberHaveSubmittedIoi}
            />
          ))}
        </div>
      </div>
    </Dialog>
  )
}

interface MemberHaveSubmittedIoiProps extends ComponentPropsWithoutRef<'div'> {
  readonly membersHaveSubmittedIoi: MemberHaveSubmittedIoiDto
  readonly onEditAmount?: () => void
  readonly onEditVisibility?: () => void
  readonly onWithdraw?: () => void
}

function MemberHaveSubmittedIoi({
  className,
  membersHaveSubmittedIoi,
  onEditAmount,
  onEditVisibility,
  onWithdraw,
  ...rest
}: MemberHaveSubmittedIoiProps) {
  const { baseUrl } = useRoutingContext()

  const { member, ioi, ioiVisibility, isCurrentUserIOI } = membersHaveSubmittedIoi

  // cover cases when it's hidden by threshold reason or it's single member who submitted IOI
  const nameIsPrivate = ioiVisibility === IoiVisibility.AMOUNT_ONLY
  const amountIsPrivate = ioiVisibility === IoiVisibility.NAME_ONLY

  let memberName = nameIsPrivate ? 'Anonymous' : `${member!.firstName} ${member!.lastName}`
  if (isCurrentUserIOI) {
    memberName += ' (You)'
  }
  let amount = amountIsPrivate ? 'Private' : formatMoney(ioi!.amount as unknown as number)
  if (amountIsPrivate && isCurrentUserIOI) {
    amount += ` (${formatMoney(ioi!.amount as unknown as number)})`
  }

  const memberTitleCompany =
    member?.title && member?.company ? `${member.title}, ${member.company}` : 'meetperry member'

  const editAmountHandler: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault()
      onEditAmount?.()
    },
    [onEditAmount],
  )

  const editVisibilityHandler: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault()
      onEditVisibility?.()
    },
    [onEditVisibility],
  )

  const withdrawHandler: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault()
      onWithdraw?.()
    },
    [onWithdraw],
  )

  return (
    <div className={cn('flex flex-col gap-3', className)} {...rest}>
      <div className="flex justify-between gap-3">
        <div className="flex justify-start gap-3">
          <AmityAvatar className="h-8 w-8 shrink-0" fileId={member?.avatarFileId} />

          <div className="flex flex-col gap-1">
            {!nameIsPrivate || isCurrentUserIOI ? (
              <Link
                className="text-nowrap text-sm font-medium leading-4 text-orange-600 hover:text-orange-800"
                href={`${baseUrl}/members/${member?.userId}`}
              >
                {memberName}
              </Link>
            ) : (
              <div className="text-twilight-gray-800 text-nowrap text-sm font-medium leading-4">
                {memberName}
              </div>
            )}

            <div className="text-twilight-gray-500 text-wrap text-sm font-medium leading-4">
              {memberTitleCompany}
            </div>

            {member?.areasOfFocus?.length && (
              <div className="flex flex-wrap gap-1">
                {member?.areasOfFocus.map((areaOfFocus) => (
                  <div
                    key={areaOfFocus}
                    className="text-twilight-gray-500 bg-twilight-gray-100 text-nowrap rounded-full px-2 py-0.5 text-sm"
                  >
                    {areaOfFocus}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="text-twilight-gray-800 text-nowrap text-right font-medium">{amount}</div>
      </div>
      {isCurrentUserIOI && (
        <div className="flex gap-2">
          <Link onClick={editAmountHandler}>
            <span className="text-nowrap font-semibold text-orange-600 hover:text-orange-800">
              Edit amount
            </span>
          </Link>
          <span className="text-orange-600">|</span>
          <Link
            className="text-nowrap font-semibold hover:text-orange-800"
            onClick={editVisibilityHandler}
          >
            <span className="text-nowrap font-semibold text-orange-600 hover:text-orange-800">
              Edit visibility
            </span>
          </Link>
          <span className="text-orange-600">|</span>
          <Link
            className="text-nowrap font-semibold hover:text-orange-800"
            onClick={withdrawHandler}
          >
            <span className="text-nowrap font-semibold text-orange-600 hover:text-orange-800">
              Withdraw
            </span>
          </Link>
        </div>
      )}
    </div>
  )
}
