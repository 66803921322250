import { useMemo, useRef, useState } from 'react'
import Image from 'next/image'
import { Dialog } from '@headlessui/react'
import { LDFlag, useFlag } from 'launchdarkly'
import { SocialSearch } from 'ui/components/navigation'
import { useOutsideClick } from 'ui/lib'
import { extraNavigation, getMainNavigation } from './data'
import Menu from './Menu'
import MenuItem from './MenuItem'
import MenuItemTransition from './MenuItemTransition'
import { SidebarContext } from './SidebarContext'
import ToggleButton from './ToggleButton'

export type MobileNavProps = {
  hideNavigation?: boolean
  hideSearchBar?: boolean
  title?: string
}

export default function SidebarMobile({ title, hideSearchBar, hideNavigation }: MobileNavProps) {
  const [open, setOpen] = useState(false)
  const showChats = useFlag<boolean>(LDFlag.SendbirdChat)
  const mainNavigation = useMemo(() => getMainNavigation(showChats), [showChats])
  const logoutItem = extraNavigation.find((item) => item.name?.toLowerCase() === 'logout')

  const navMenuRef = useRef<HTMLUListElement>(null)
  const userMenuRef = useRef<HTMLUListElement>(null)
  const logoutMenuItemRef = useRef<HTMLLIElement>(null)
  useOutsideClick([navMenuRef, userMenuRef, logoutMenuItemRef], () => setOpen(false))

  const [isSocialSearchFocused, setIsSocialSearchFocused] = useState(false)

  return (
    <SidebarContext.Provider
      value={{
        isOpen: open,
        isClosed: !open,
        openSidebar: () => setOpen(true),
        closeSidebar: () => setOpen(false),
      }}
    >
      <Dialog open={open} onClose={setOpen} className="relative z-50 lg:hidden">
        <Dialog.Backdrop className="fixed inset-0 bg-gray-900/80 transition-opacity duration-200 ease-linear data-[closed]:opacity-0" />

        <div className="fixed inset-0 flex">
          <Dialog.Panel className="relative flex w-full flex-1 transform transition duration-200 ease-in-out data-[closed]:-translate-x-full">
            {/* Sidebar */}
            <div
              className="bg-deep-teal-500 flex grow flex-col gap-y-5 overflow-y-auto px-5 py-5"
              onClick={() => setOpen(false)}
            >
              <div className="flex items-center gap-5 px-1">
                <ToggleButton onClick={() => setOpen(false)} />

                <MenuItemTransition show={open}>
                  <Image src="/images/meetperry-white.svg" height={24} width={121} alt="" />
                </MenuItemTransition>
              </div>

              {hideNavigation ? (
                <div className="flex-1" />
              ) : (
                <Menu ref={navMenuRef} className="mt-14 flex-1" items={mainNavigation} />
              )}

              <hr className="my-2 border-white/40" />

              {!hideNavigation && <Menu ref={userMenuRef} items={extraNavigation} />}

              {hideNavigation && logoutItem && (
                <MenuItem
                  ref={logoutMenuItemRef}
                  name={logoutItem.name}
                  href={logoutItem.href}
                  icon={logoutItem.icon}
                />
              )}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Top navbar */}
      <div className="bg-deep-teal-500 sticky top-0 z-40 flex h-16 shrink-0 items-center gap-5 px-6 lg:hidden">
        {!isSocialSearchFocused && (
          <>
            <ToggleButton onClick={() => setOpen(true)} />
            <Image
              src="/images/meetperry-mobile-white.svg"
              className="mt-1"
              height={19}
              width={34}
              alt=""
            />
          </>
        )}
        {hideSearchBar ? (
          <p className="text-lg/6 tracking-[0.01em] text-white">{title}</p>
        ) : (
          <SocialSearch
            className="flex-grow lg:hidden"
            isRightAlignedBeforeFocused
            isFocused={isSocialSearchFocused}
            setIsFocused={setIsSocialSearchFocused}
          />
        )}
      </div>
    </SidebarContext.Provider>
  )
}
