import { formatMoney } from 'ui/src/lib/formatters'
import { number, object } from 'yup'

export const useAmountValidator = (min: number, max: number) => {
  return object().shape({
    amount: number()
      .required('Amount of investment is required.')
      .typeError('Amount of investment is required.')
      .min(min, `Minimum investment is ${formatMoney(min)}.`)
      .max(max, `Maximum investment is ${formatMoney(max)}.`),
  })
}
