import { ComponentProps } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { Button } from 'ui/src/components/forms'
import { Alert } from '../Alert'
import { Dialog } from '../Dialog'

interface CoreIoiApprovedDialogProps
  extends Omit<ComponentProps<typeof Dialog>, 'actions' | 'onSubmit'> {
  dataRoomUrl: string
}

export function CoreIoiApprovedDialog({ dataRoomUrl, onClose, ...props }: CoreIoiApprovedDialogProps) {
  const actions = (
    <Button
      className="w-full font-medium"
      href={dataRoomUrl}
      onClick={() => onClose()}
      target="_blank"
    >
      Access Data Room
    </Button>
  )

  return (
    <Dialog actions={actions} onClose={onClose} {...props}>
      <div className="flex h-full flex-col justify-center">
        <div className="mb-2 flex justify-center">
          <div className="bg-success-card-green-300 text-success-card-green-800 w-fit rounded-full p-5">
            <CheckCircleIcon className="h-14 w-14" />
          </div>
        </div>
        <div className="space-y-2 text-center text-sm font-normal">
          <Dialog.Title as="h3" className="text-lg font-normal leading-6 text-gray-900">
            Thank you!
          </Dialog.Title>
          <div>
            Your access has been approved. You will be redirected to the Data Room after clicking
            the button below. If you don&apos;t yet have an Anduin account, you will be asked to
            create one.
          </div>
          <Alert className="mt-2 w-full">
            Note that submitting an IOI does not secure your allocation! Please make sure to fill
            out the subscription documents if you wish to invest.
          </Alert>
        </div>
      </div>
    </Dialog>
  )
}
