import { FC } from 'react'
import clsx from 'clsx'
import { Button, ButtonProps } from 'ui/src/components/forms'

export const ActionButton: FC<ButtonProps<HTMLElement>> = ({ children, className, ...props }) => {
  return (
    <>
      {/* desktop */}
      <Button className={clsx(className, 'hidden sm:flex')} size="sm" {...props}>
        {children}
      </Button>
      {/* mobile */}
      <Button className={clsx(className, 'flex sm:hidden')} size="lg" {...props}>
        {children}
      </Button>
    </>
  )
}
