import { ComponentPropsWithoutRef } from 'react'
import { MemberHaveSubmittedIoi } from 'api/dto'
import { take } from 'lodash'
import { AmityAvatar, AvatarGroup } from 'ui/components/content'
import { cn } from 'ui/lib'
import { formatMoneyShort } from 'ui/src/lib/formatters'

type uiSize = 'sm' | 'lg'

const AVATAR_CLASSES = {
  sm: 'h-4 w-4 ring-1',
  lg: 'h-5 w-5 ring-2',
}

const LABELS_CLASSES = {
  sm: 'text-xs',
  lg: 'text-sm',
}

const DIVIDER_CLASSES = {
  sm: 'h-4',
  lg: 'h-5',
}

interface TotalIoisPreviewProps extends ComponentPropsWithoutRef<'div'> {
  uiSize: uiSize
  totalIois: number
  membersHaveSubmittedIoi: MemberHaveSubmittedIoi[]
}

export function TotalIoisPreview({
  className,
  uiSize,
  totalIois,
  membersHaveSubmittedIoi,
  ...rest
}: TotalIoisPreviewProps) {
  const membersHaveSubmittedIoiWithAvatarsFirst = membersHaveSubmittedIoi.sort(
    (a, b) => (a.member?.avatarFileId ? -1 : 1) - (b.member?.avatarFileId ? -1 : 1),
  )

  return (
    <div className={cn('justify-left flex items-center gap-2', className)} {...rest}>
      <div
        className={cn(
          'text-deep-teal-500 flex gap-1 whitespace-nowrap font-semibold',
          'group-hover:text-white', // Rough hack to change text color when investments card on main page os hovered
          LABELS_CLASSES[uiSize],
        )}
      >
        <span>{`${formatMoneyShort(totalIois)} in IOIs`}</span>
        <div className={cn('h-4 w-0.5 shrink-0 bg-orange-500', DIVIDER_CLASSES[uiSize])}></div>
        <span>{`${membersHaveSubmittedIoi.length} Member${
          membersHaveSubmittedIoi.length === 1 ? '' : 's'
        }`}</span>
        <AvatarGroup className="flex self-start">
          {take(membersHaveSubmittedIoiWithAvatarsFirst, 3).map((memberHaveSubmittedIoi) => (
            <AmityAvatar
              className={cn('shrink-none flex rounded-full ring-white', AVATAR_CLASSES[uiSize])}
              fileId={memberHaveSubmittedIoi.member?.avatarFileId!}
              key={memberHaveSubmittedIoi.id}
            />
          ))}
        </AvatarGroup>
      </div>
    </div>
  )
}
