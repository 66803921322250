import { FC } from 'react'
import { Dialog, DialogProps } from 'ui/components/containers/Dialog2'

export type FullScreenDialogProps = Omit<
  DialogProps,
  'showBackdrop' | 'width' | 'containerClassName' | 'fullScreen'
>

export const FullScreenDialog: FC<FullScreenDialogProps> = ({
  open,
  onClose,
  title,
  actions,
  children,
  ...props
}) => {
  return (
    <Dialog
      actions={
        <div className="bg-deep-gray-50 flex w-screen flex-col gap-4 px-4 pb-6 pt-4">{actions}</div>
      }
      open={open}
      showCloseButton={true}
      title={<div className="px-4">{title}</div>}
      fullScreenMobile
      onClose={onClose}
      {...props}
    >
      <div className="px-4 pt-4">{children}</div>
    </Dialog>
  )
}
