import { ComponentPropsWithoutRef, FC } from 'react'
import ReactTooltip from 'react-tooltip'
import { InvestmentOpportunityStage } from 'api/cms'
import { Link } from 'ui/components/navigation'
import MeetperryIcon from './MeetperryIcon'
import OffPisteIcon from './OffPisteIcon'
import PipelineIcon from './PipelineIcon'
import { cn } from '../../../lib'

const MARKETPLACE_TAG_MAP: Record<
  InvestmentOpportunityStage,
  {
    title: string
    icon: FC<ComponentPropsWithoutRef<'svg'>>
    link: string
    className: string
    tooltipText?: string
  }
> = {
  core: {
    title: 'CORE OPPORTUNITY',
    icon: MeetperryIcon,
    link: '/investments',
    className: 'bg-deep-teal-500',
  },
  pipeline: {
    title: 'PIPELINE',
    icon: PipelineIcon,
    link: '/investments/pipeline',
    className: 'bg-navy-400',
    tooltipText:
      'This deal is currently in early due diligence stage by meetperry team and the Field Experts.',
  },
  offpiste: {
    title: 'OFF-PISTE',
    icon: OffPisteIcon,
    link: '/investments/offpiste',
    className: 'bg-navy-400',
    tooltipText:
      'This deal has been submitted for consideration by a meetperry member and has not yet been reviewed by meetperry team and Field Experts.',
  },
}

interface InvestmentMarketplaceTagProps {
  stage: InvestmentOpportunityStage
  showTooltip?: boolean
}

export const InvestmentMarketplaceTag: FC<InvestmentMarketplaceTagProps> = ({
  stage,
  showTooltip = false,
}) => {
  const tag = MARKETPLACE_TAG_MAP[stage]

  if (!tag) return

  const TagIcon = tag.icon

  return (
    <div
      className={cn(
        'relative flex w-fit items-center space-x-1 whitespace-nowrap rounded px-1 py-0.5 text-xs font-semibold text-white',
        tag.className,
        tag.tooltipText && 'cursor-pointer',
      )}
      data-tip
      data-event="click"
      data-for="tag-tooltip"
    >
      <div className="size-3 shrink-0 content-center">
        <TagIcon />
      </div>
      <span className="whitespace-nowrap">{tag.title}</span>
      {showTooltip && tag.tooltipText && (
        <ReactTooltip
          id="tag-tooltip"
          clickable
          className="z-10 max-w-[240px] whitespace-normal"
          globalEventOff="click"
        >
          <Link href={tag.link} className="text-white">
            {tag.tooltipText}
          </Link>
        </ReactTooltip>
      )}
    </div>
  )
}
