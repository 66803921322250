import {
  Children,
  cloneElement,
  ComponentProps,
  FC,
  forwardRef,
  isValidElement,
  ReactNode,
} from 'react'
import {
  RadioGroup as HeadlessRadioGroup,
  RadioGroupProps as HeadlessRadioGroupProps,
} from '@headlessui/react'
import clsx from 'clsx'

export interface RadioGroupProps extends HeadlessRadioGroupProps<'div', string> {
  readonly label?: string
}

// eslint-disable-next-line react/display-name
export const RadioGroup = forwardRef<any, RadioGroupProps>(
  (
    {
      name,
      label,
      value = '',
      onChange = () => {
        /* noop */
      },
      disabled,
      children,
      ...props
    },
    ref,
  ) => {
    const childrenWithProps = Children.map(children as ReactNode, (child) => {
      // Checking isValidElement is the safe way
      // and avoids a typescript error too.
      if (isValidElement(child)) {
        return cloneElement<any>(child, {
          disabled: disabled || child.props.disabled,
          name,
          onChange,
        })
      }
      return child
    })

    return (
      <HeadlessRadioGroup ref={ref} value={value} onChange={onChange} {...props}>
        {label && (
          <HeadlessRadioGroup.Label className="sr-only" data-testid="rg-label">
            {label}
          </HeadlessRadioGroup.Label>
        )}
        <div className={clsx({ 'opacity-25': disabled }, '-space-y-px rounded-md bg-white')}>
          {childrenWithProps}
        </div>
      </HeadlessRadioGroup>
    )
  },
)

// RADIO OPTION

export interface RadioOptionProps extends ComponentProps<'input'> {
  readonly label?: string
  readonly description?: string
}

export const RadioOption: FC<RadioOptionProps> = ({
  label,
  description,
  value,
  children,
  disabled,
  className,
}) => {
  const labelContent = label || children

  return (
    <HeadlessRadioGroup.Option
      value={value}
      disabled={disabled}
      className={({ checked }) =>
        clsx(
          className,
          checked ? 'bg-deep-teal-50' : '',
          'group relative flex cursor-pointer p-4 focus:outline-none',
        )
      }
    >
      {({ checked }) => (
        <>
          <span
            className={clsx(
              'mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border',
              checked
                ? 'bg-deep-teal-400 border-deep-teal-400 group-hover:bg-deep-teal-500 group-focus:bg-deep-teal-500 group-focus:border-deep-teal-900'
                : 'border-deep-teal-300 group-hover:border-deep-teal-500 group-focus:bg-deep-teal-100 bg-transparent',
            )}
            aria-hidden="true"
          >
            <span
              className={clsx(
                'h-1.5 w-1.5 rounded-full',
                checked && 'group-focus:bg-deep-teal-100 group-hover:bg-deep-teal-100 bg-white',
              )}
            />
          </span>
          <span className="ml-3 flex flex-col">
            <HeadlessRadioGroup.Label
              as="span"
              className="text-deep-teal-500 block text-sm font-medium leading-5"
            >
              {labelContent}
            </HeadlessRadioGroup.Label>

            <HeadlessRadioGroup.Description
              as="span"
              className="text-deep-teal-400 block text-sm leading-5"
            >
              {description}
            </HeadlessRadioGroup.Description>
          </span>
        </>
      )}
    </HeadlessRadioGroup.Option>
  )
}
