import { ComponentType, createContext, PropsWithChildren, ReactElement } from 'react'

export type PlatformDialogWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type PlatformDialogProps = PropsWithChildren<{
  readonly open: boolean
  readonly title?: string | ReactElement
  readonly containerClassName?: string
  readonly onClose?: () => void
  readonly showCloseButton?: boolean
  readonly width?: PlatformDialogWidth
}>

export type PlatformDialogContextValue = {
  component: ComponentType<PlatformDialogProps>
}

export const PlatformDialogContext = createContext<PlatformDialogContextValue>({
  component: () => {
    throw new Error('PlatformDialogContext is not provided')
  },
})
