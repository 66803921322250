import { ComponentProps } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { Button } from 'ui/src/components/forms'
import { Alert } from '../Alert'
import { Dialog } from '../Dialog'

type OffPistePipelineIoiSubmittedDialogProps = Omit<
  ComponentProps<typeof Dialog>,
  'actions' | 'onSubmit'
>

export function OffPistePipelineIoiSubmittedDialog({
  onClose,
  ...props
}: OffPistePipelineIoiSubmittedDialogProps) {
  const actions = (
    <Button className="w-full font-medium" onClick={() => onClose()}>
      OK
    </Button>
  )

  return (
    <Dialog actions={actions} onClose={onClose} {...props}>
      <div className="flex h-full flex-col justify-center">
        <div className="mb-2 flex justify-center">
          <div className="bg-success-card-green-300 text-success-card-green-800 w-fit rounded-full p-5">
            <CheckCircleIcon className="h-14 w-14" />
          </div>
        </div>
        <div className="space-y-2 text-center text-sm font-normal">
          <Dialog.Title as="h3" className="text-lg font-normal leading-6 text-gray-900">
            Thank you!
          </Dialog.Title>

          <Alert className="w-full" type="info">
            Your IOI has been submitted. We will notify you if this opportunity becomes a Core
            Opportunity offered for investment.
          </Alert>
        </div>
      </div>
    </Dialog>
  )
}
