import { type ReactNode } from 'react'
import { SessionHandler } from '@sendbird/chat'
import SBProvider from '@sendbird/uikit-react/SendbirdProvider'
import { axios, useAxios } from 'api/lib'
import { LDFlag, useFlag } from 'launchdarkly'
import { sendError } from 'ui/lib/logger'

const myColorSet = {
  // primary
  '--sendbird-light-primary-500': '#072529', // primary-700
  '--sendbird-light-primary-400': '#0D2C30', // primary-600
  '--sendbird-light-primary-300': '#0A373C', // primary-500
  '--sendbird-light-primary-200': '#456063', // primary-400
  '--sendbird-light-primary-100': '#73888A', // primary-300

  // font family
  '--sendbird-font-family-default':
    'Inter var, Arial, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
}

type SendbirdSession = {
  sendbirdSessionToken: string
  sendbirdUserId: string
}

function configureSendbirdSession(): SessionHandler {
  return new SessionHandler({
    onSessionTokenRequired(resolve, reject) {
      axios
        .post<SendbirdSession>('/api/sendbird/session')
        .then(({ data }) => resolve(data.sendbirdSessionToken))
        .catch((error) => {
          sendError({ message: 'Failed to refresh Sendbird session', error })
          reject(error)
        })
    },
  })
}

function useSendbirdSession(): Partial<SendbirdSession> {
  const enableChats = useFlag(LDFlag.SendbirdChat)

  const { data } = useAxios<SendbirdSession>(
    enableChats && '/api/sendbird/session',
    {
      // Disable all automatic revalidation, fetch only once.
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
    { method: 'POST' },
  )

  return data || {}
}

export function SendbirdProvider({ children }: { children: ReactNode }) {
  const { sendbirdUserId, sendbirdSessionToken } = useSendbirdSession()

  return (
    <SBProvider
      appId={process.env.NEXT_PUBLIC_SENDBIRD_APP_ID}
      userId={sendbirdUserId}
      accessToken={sendbirdSessionToken}
      breakpoint="1280px" // Tailwind CSS "md" breakpoint.
      configureSession={configureSendbirdSession}
      uikitOptions={{
        groupChannel: { enableMention: true },
      }}
      colorSet={myColorSet}
    >
      {children}
    </SBProvider>
  )
}
