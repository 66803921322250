import { ComponentProps } from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { UserSocialProfilePreview } from 'api/dto'
import { take } from 'lodash'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'
import { useRoutingContext } from 'ui/lib/navigation'
import { AmityAvatar } from '../Avatar'
import { AvatarGroup } from '../AvatarGroup'

interface HasNominatedProps extends ComponentProps<'div'> {
  readonly className?: string
  readonly members: UserSocialProfilePreview[]
}

export function HasNominated({
  className,
  members,
  ...props
}: HasNominatedProps) {
  const { baseUrl } = useRoutingContext()
  const remainingNominationsCount = members.length - 3

  return (
    <div
      className={cn(
        'border-deep-teal-100 flex gap-3 rounded-lg border bg-white px-4 py-3',
        className,
      )}
      {...props}
    >
      <span className="text-deep-teal-400 whitespace-nowrap text-xs leading-6">
        {members.length ? 'Members Nominated' : 'Hit the button to nominate a member'}
      </span>
      <Link href={`${baseUrl}/nominated-members`} className="flex">
        <div className="flex gap-2">
          <AvatarGroup className="flex-none">
            {take(members, 3).map((nomination) => (
              <AmityAvatar
                className="flex h-6 w-6 shrink-0 rounded-full ring-2 ring-white"
                fileId={nomination.avatarFileId!}
                key={nomination.id}
              />
            ))}
          </AvatarGroup>
          {remainingNominationsCount > 0 ? (
            <span className="text-deep-teal-400 flex-none whitespace-nowrap text-xs leading-6">{`+${remainingNominationsCount}`}</span>
          ) : null}
        </div>
      </Link>
      {!!members.length && <div className="bg-deep-teal-100 ml-auto h-6 w-px flex-none" />}
      <Link
        href={`${baseUrl}/nominations`}
        className="group"
        data-testid="nominations-link"
        data-trackclick={JSON.stringify({ action: 'Clicked Nominate Member - Homepage' })}
      >
        <PlusCircleIcon className="h-6 w-6 flex-none text-orange-600 group-hover:text-orange-800" />
      </Link>
    </div>
  )
}
