import { ComponentProps, FC } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { InvestmentOpportunityExtended } from 'api/dto'
import { AltInvestmentTypeTag, TotalIoisPreview } from 'ui/components/investments'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'

export interface InvestmentCardProps extends ComponentProps<'div'> {
  readonly investment: InvestmentOpportunityExtended
}

export const InvestmentCard: FC<InvestmentCardProps> = ({ className, investment, ...props }) => {
  const hasTotalIois =
    (investment.totalIOIs ?? 0 > 0) && (investment.membersHaveSubmittedIOI?.length ?? 0 > 0)

  const { investmentOpportunityType, fundType } = investment

  return (
    <Link href={`/investments/${investment.id}`}>
      <div
        className={cn(
          'border-deep-teal-100 flex h-fit flex-col justify-center gap-3 rounded-lg border px-4 py-6',
          className,
        )}
        {...props}
      >
        <div className={cn('flex h-5 justify-end', { 'justify-between': hasTotalIois })}>
          {hasTotalIois && (
            <TotalIoisPreview
              uiSize="sm"
              totalIois={investment.totalIOIs!}
              membersHaveSubmittedIoi={investment.membersHaveSubmittedIOI!}
            />
          )}
          <State className="shrink-0 grow-0" state={investment.state} />
        </div>
        <div className="flex items-start justify-between gap-3">
          <img
            className="h-11 w-11 shrink-0 grow-0 object-contain"
            src={investment.sponsor?.logo?.url}
          />

          <div className="flex-1 space-y-1">
            <div className="text-deep-teal-500 text-base font-semibold leading-5">
              {investment.title}
            </div>
            {investmentOpportunityType ? (
              <AltInvestmentTypeTag
                className="block w-fit"
                slug={investmentOpportunityType.slug}
                typeName={investmentOpportunityType.name}
              />
            ) : (
              <div className="h-5"></div>
            )}
            {fundType ? (
              <div className="text-deep-teal-400 h-4 text-xs group-hover:text-white">
                {fundType}
              </div>
            ) : (
              // fundType placeholder
              <div className="h-4"></div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export interface StateProps extends ComponentProps<'div'> {
  readonly state?: string
}

export const State: FC<StateProps> = ({ state, className, ...props }) => {
  return (
    <div className={cn('flex h-3 items-center gap-1', className)} {...props}>
      {state === 'new' ? (
        <>
          <StarIcon data-testid="star-icon" className="text-navy-400 h-3 w-3" />
          <span className="text-navy-400 text-xs font-semibold">NEW</span>
        </>
      ) : state === 'closed' ? (
        <>
          <MinusCircleIcon data-testid="minus-circle-icon" className="text-taupe-700 h-3 w-3" />
          <span className="text-taupe-700 text-xs font-semibold">CLOSED</span>
        </>
      ) : (
        <>{/* Intentionally left empty */}</>
      )}
    </div>
  )
}
