import { FC } from 'react'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { cn } from 'ui/lib'

interface FilterSectionProps {
  title: string
  children: React.ReactNode
  displayClearButton?: boolean
  onClear?: () => void
  className?: string
}

export const FilterSection: FC<FilterSectionProps> = ({
  title,
  children,
  displayClearButton,
  onClear,
  className,
}) => (
  <div className={cn('flex flex-col gap-4', className)}>
    <div className="flex items-center justify-between">
      <h3 className="text-deep-gray-600 text-sm font-medium">{title}</h3>
      {displayClearButton && (
        <button
          className={cn(
            'flex flex-nowrap items-center space-x-2',
            'cursor-pointer text-sm font-semibold leading-5',
            'text-orange-600 hover:text-orange-800',
          )}
          onClick={() => onClear?.()}
        >
          <XCircleIcon className="h-4 w-4" />
          <span className="whitespace-nowrap text-sm">Clear</span>
        </button>
      )}
    </div>
    <div className="bg-deep-teal-100 h-px" />
    {children}
  </div>
)
