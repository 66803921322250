import { ComponentProps, FC } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { InvestmentOpportunityExtended } from 'api/dto'
import { AltInvestmentTypeTag, TotalIoisPreview } from 'ui/components/investments'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'

export interface InvestmentFullSizeCardProps extends ComponentProps<'a'> {
  readonly investment: InvestmentOpportunityExtended
}

export const InvestmentFullSizeCard: FC<InvestmentFullSizeCardProps> = ({
  className,
  investment,
  ...props
}) => {
  const hasTotalIois = !!investment.totalIOIs && !!investment.membersHaveSubmittedIOI?.length

  const { investmentOpportunityType, fundType } = investment

  return (
    <Link className={className} href={`/investments/${investment.id}`} {...props}>
      <div
        className={cn(
          'border-deep-teal-100 flex h-full flex-col justify-center gap-3 rounded-lg border px-4 py-6',
          'hover:bg-deep-teal-500 hover:border-deep-teal-500 group',
        )}
      >
        <div className="flex w-full items-start justify-between">
          <img
            className="h-9 w-9 shrink-0 grow-0 object-contain"
            src={investment.sponsor?.avatar?.url}
          />
          <State className="shrink-0 grow-0 justify-end" state={investment.state} />
        </div>
        <div className="flex-1 space-y-1">
          <div className="text-deep-teal-500 h-10 text-base font-semibold leading-5 group-hover:text-white">
            {investment.title}
          </div>
          {investmentOpportunityType ? (
            <AltInvestmentTypeTag
              className="block w-fit"
              slug={investmentOpportunityType.slug}
              typeName={investmentOpportunityType.name}
            />
          ) : (
            <div className="h-5"></div>
          )}
          {fundType ? (
            <div className="text-deep-teal-400 h-4 text-xs group-hover:text-white">{fundType}</div>
          ) : (
            // FundType placeholder
            <div className="h-4"></div>
          )}
        </div>

        {hasTotalIois ? (
          <TotalIoisPreview
            uiSize="sm"
            totalIois={investment.totalIOIs!}
            membersHaveSubmittedIoi={investment.membersHaveSubmittedIOI!}
          />
        ) : (
          <div className="h-4"></div>
        )}
      </div>
    </Link>
  )
}

export interface StateProps extends ComponentProps<'div'> {
  readonly state?: string
}

export const State: FC<StateProps> = ({ state, className, ...props }) => {
  return (
    <div className={cn('flex items-center gap-1', className)} {...props}>
      {state === 'new' ? (
        <>
          <StarIcon
            data-testid="star-icon"
            className="text-navy-400 h-3 w-3 group-hover:text-white"
          />
          <span className="text-navy-400 text-xs font-semibold group-hover:text-white">NEW</span>
        </>
      ) : state === 'closed' ? (
        <>
          <MinusCircleIcon
            data-testid="minus-circle-icon"
            className="text-taupe-700 h-3 w-3 group-hover:text-white"
          />
          <span className="text-taupe-700 text-xs font-semibold group-hover:text-white">
            CLOSED
          </span>
        </>
      ) : (
        <>{/* Intentionally left empty */}</>
      )}
    </div>
  )
}
