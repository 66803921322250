import { ComponentProps } from 'react'
import { BookmarkIcon as OutlineBookmarkIcon } from '@heroicons/react/24/outline'
import { BookmarkIcon as SolidBookmarkIcon } from '@heroicons/react/24/solid'
import { axios } from 'api/lib'
import { cn, useDependentState } from 'ui/lib'
import { analytics } from 'ui/lib/analytics'

interface BookmarkButtonProps extends ComponentProps<'button'> {
  investmentId: number
  isBookmarked: boolean
  onChange: () => void
}

export function BookmarkButton({
  className,
  investmentId,
  isBookmarked,
  onChange,
}: BookmarkButtonProps) {
  const [isBookmarkedActive, setIsBookmarkedActive] = useDependentState<boolean>(isBookmarked)

  const handleBookmarkClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setIsBookmarkedActive((prev) => !prev)

    try {
      await axios.post(`/api/investment-opportunities/${investmentId}/toggle-bookmark`)

      const trackMessage = isBookmarkedActive
        ? 'Investment Opportunity removed from bookmarks'
        : 'Investment Opportunity added to bookmarks'
      analytics?.track(trackMessage, {
        action: trackMessage,
        investmentId: investmentId,
      })

      onChange()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <button className={cn('text-deep-teal-500 h-4 w-4', className)} onClick={handleBookmarkClick}>
      {isBookmarkedActive ? (
        <SolidBookmarkIcon data-testid="solid-bookmark-button" />
      ) : (
        <OutlineBookmarkIcon data-testid="outline-bookmark-button" />
      )}
    </button>
  )
}
