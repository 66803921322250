import { ComponentProps } from 'react'
import { Dialog as HeadlessDialog } from '@headlessui/react'
import { Dialog as BaseDialog } from 'ui/components/containers/Dialog2'

interface DialogProps
  extends Omit<ComponentProps<typeof BaseDialog>, 'open' | 'fullScreenMobile' | 'onClose'> {
  readonly actions?: JSX.Element
  readonly onClose: () => void
}

export function Dialog({ children, actions, ...rest }: DialogProps) {
  return (
    <BaseDialog
      fullScreenMobile
      open
      containerClassName="block -m-6 p-6"
      actions={
        actions && (
          <div className="bg-deep-gray-50 flex w-full flex-col gap-4 px-4 pb-6 pt-4 sm:w-full sm:flex-row-reverse sm:bg-transparent sm:p-0">
            {actions}
          </div>
        )
      }
      {...rest}
    >
      {/* overflow-hidden to prevent scrolling inside child div*/}
      {/* dialog container will scrollable instead */}
      {/* bottom padding added to prevent content be sticky to the bottom */}
      <div className="overflow-hidden px-4 pb-4 sm:px-0 sm:pb-0">{children}</div>
    </BaseDialog>
  )
}

// Re-export HeadlessDialog components
Dialog.Backdrop = HeadlessDialog.Backdrop
Dialog.Panel = HeadlessDialog.Panel
Dialog.Overlay = HeadlessDialog.Overlay
Dialog.Title = HeadlessDialog.Title
Dialog.Description = HeadlessDialog.Description
