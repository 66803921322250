import { ComponentProps } from 'react'
import { cn } from 'ui/lib'

export function WelcomeLoading({ className, ...props }: ComponentProps<'div'>) {
  return (
    <div
      className={cn(
        'flex w-full animate-pulse flex-col gap-y-6 lg:gap-y-4 xl:w-[400px]',
        className,
      )}
      {...props}
    >
      <div>
        <div className="flex flex-col gap-x-2">
          <div className="h-8 w-11/12 bg-slate-200" />
          <div className="mt-1 h-4 w-3/4 bg-slate-200" />
          <div className="mt-1 h-4 w-full bg-slate-200" />
        </div>
      </div>
      <div className="border-stale-200 flex h-12 justify-between rounded-lg border bg-white p-3 lg:hidden">
        <div className="h-6 w-5/12 bg-slate-200" />
        <div className="h-6 w-6 rounded-full bg-slate-200" />
      </div>
    </div>
  )
}
