import type { ComponentProps, FC } from 'react'
import {
  ArrowRightOnRectangleIcon,
  ChartPieIcon,
  ChatBubbleOvalLeftIcon,
  HomeIcon,
  Square3Stack3DIcon,
  UserCircleIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'

export type MenuItemType = {
  name: string
  href?: string
  icon?: FC<ComponentProps<'svg'>>
  items?: MenuItemType[]
}

export function getMainNavigation(showChats: boolean): MenuItemType[] {
  const clubItems = clubNavigation.items.filter((item) => showChats || item.name !== 'Chats')
  return [
    homeNavigation,
    alternativesNavigation,
    { ...clubNavigation, items: clubItems },
    wealthChannelsNavigation,
  ]
}

const homeNavigation: MenuItemType = {
  name: 'Home',
  href: '/',
  icon: HomeIcon,
}

const alternativesNavigation: MenuItemType = {
  name: 'Alternatives',
  icon: ChartPieIcon,
  items: [
    {
      name: 'Investments',
      href: '/investments',
    },
    {
      name: 'Portfolio',
      href: '/portfolio',
    },
    {
      name: 'Data Room',
      href: 'https://dataroom.anduin.app/#/dashboard/all/active',
    },
  ],
}

const clubNavigation: MenuItemType = {
  name: 'Club',
  icon: ChatBubbleOvalLeftIcon,
  items: [
    {
      name: 'Member Directory',
      href: '/member-directory',
    },
    {
      name: 'Chats',
      href: '/chats',
    },
    {
      name: 'Articles',
      href: '/articles',
    },
    {
      name: 'Events',
      href: '/events',
    },
    {
      name: 'Groups',
      href: '/groups',
    },
    {
      name: 'Member Perks',
      href: '/member-perks',
    },
    {
      name: 'Philanthropy',
      href: '/philanthropy',
    },
  ],
}

const wealthChannelsNavigation: MenuItemType = {
  name: 'Wealth Channels',
  icon: Square3Stack3DIcon,
  items: [
    {
      name: 'Insurance',
      href: '/wealth-channels/insurance',
    },
  ],
}

export const extraNavigation: MenuItemType[] = [
  {
    name: 'Profile',
    href: '/profile',
    icon: UserCircleIcon,
  },
  {
    name: 'Settings',
    href: '/profile/settings',
    icon: WrenchIcon,
  },
  {
    name: 'Logout',
    href: '/logout',
    icon: ArrowRightOnRectangleIcon,
  },
]
