import { forwardRef, InputHTMLAttributes } from 'react'
import { Control, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import clsx from 'clsx'
import { formatMoney } from 'ui/src/lib/formatters'

const parseToNumber = (str?: string): number | undefined => {
  if (!str) return
  const stringNumber = str.replace(/[^0-9.]/g, '')
  return Number.parseFloat(stringNumber)
}

export interface CurrencyInputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly control?: Control<any>
}

// eslint-disable-next-line react/display-name
export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ name, className, control, ...props }, ref) => {
    // onChange is not allowed for IMaskInput
    // https://www.npmjs.com/package/react-imask
    // DO NOT USE onChange TO HANDLE CHANGES!
    // USE onAccept INSTEAD!
    delete props.onChange
    return (
      <Controller
        name={name as string}
        control={control}
        render={({ field }) => (
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <IMaskInput
              ref={ref}
              type="text"
              lazy={true}
              mask={Number}
              signed={false}
              thousandsSeparator={','}
              padFractionalZeros={true}
              radix={'.'}
              name={name}
              value={formatMoney(field.value)}
              onBlur={field.onBlur}
              onAccept={(value?: string) => field.onChange(parseToNumber(value))}
              className={clsx('pl-6', className)}
              {...props}
            />
          </div>
        )}
      />
    )
  },
)

export default CurrencyInput
